import Home from "@/views/pc/public/Home.vue";

export default [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        // 懒加载
        component: () => import("@/views/pc/public/user/Login.vue"),
        meta: {
            title: "你好，请登录"
        }
    },
    {
        path: "/plan",
        name: "plan",
        // 懒加载
        component: () => import("@/views/pc/public/Plan.vue"),
        meta: {
            title: "朝雨的个人站 - 学习路线"
        }
    },
    {
        path: "/course",
        name: "course",
        // 懒加载
        component: () => import("@/views/pc/public/Course.vue"),
        meta: {
            title: "朝雨的个人站 - 视频教程"
        }
    },
    {
        path: "/note",
        name: "note",
        // 懒加载
        component: () => import("@/views/pc/public/Note.vue"),
        meta: {
            title: "朝雨的个人站 - 笔记"
        }
    }
];

import Vue from "vue";
import VueRouter from "vue-router";
import PublicRouter from "./public.js";
import PrivateRouter from "./private.js";
import AdminRouter from "./admin.js";

Vue.use(VueRouter);

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err);
// };

const baseRoutes = [];
const routes = baseRoutes.concat(PublicRouter, PrivateRouter, AdminRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;

<template>
    <div>
        <el-menu default-active="" @open="handleOpen" @close="handleClose" background-color="#e3e9f1" text-color="#000" active-text-color="#1e82e8">
            <div v-for="(item, index) in sidebarData" :key="index">
                <el-submenu v-if="item.children" :index="index + '-' + item.url">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.label }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item
                            v-for="(subItem, subIndex) in item.children"
                            :key="index + '-' + subIndex"
                            :index="index + '-' + subIndex + '-' + subItem.url"
                            @click.native="clickSidebarMenu(subItem.label, subItem.url, subItem.open_tab)"
                        >
                            <i :class="subItem.icon"></i>
                            <span slot="title">{{ subItem.label }}</span>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item v-else :index="index + '-' + item.url" @click.native="clickSidebarMenu(item.label, item.url, item.open_tab)">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.label }}</span>
                </el-menu-item>
            </div>
        </el-menu>
    </div>
</template>

<script>
import { apiGetUser } from "@/api/private/user.js";
//判断用户类型，然后显示对应的菜单
export default {
    data() {
        return {
            user: {},
            sidebarData: [
                {
                    label: "课程管理",
                    url: "/admin/course/list",
                    icon: "el-icon-video-camera",
                    open_tab: true,
                    children: null
                },
                {
                    label: "资料管理",
                    url: "",
                    icon: "el-icon-document-copy",
                    children: [
                        {
                            label: "管理笔记",
                            url: "/admin/note/list",
                            icon: "el-icon-notebook-2",
                            open_tab: true
                        },
                        {
                            label: "管理标签",
                            url: "/admin/pc/tag/list",
                            icon: "el-icon-price-tag",
                            open_tab: true
                        },
                        {
                            label: "管理评论",
                            url: "/admin/pc/comment/manage",
                            icon: "el-icon-chat-line-square",
                            open_tab: true
                        }
                    ]
                },
                {
                    label: "用户管理",
                    url: "",
                    icon: "el-icon-school",
                    children: [
                        {
                            label: "管理用户",
                            url: "/admin/manage_articles",
                            icon: "el-icon-user",
                            open_tab: true
                        },
                        {
                            label: "拉黑记录",
                            url: "/admin/manage_category",
                            icon: "el-icon-folder-delete",
                            open_tab: true
                        },
                        {
                            label: "用户协议",
                            url: "/admin/pc/user/agreement",
                            icon: "el-icon-s-check",
                            open_tab: true
                        },
                        {
                            label: "隐私政策",
                            url: "/admin/pc/user/privacy_policy",
                            icon: "el-icon-s-check",
                            open_tab: true
                        }
                    ]
                },
                {
                    label: "系统设置",
                    url: "/admin/system_settings",
                    icon: "el-icon-setting",
                    open_tab: true,
                    children: [
                        {
                            label: "短信网关",
                            url: "/admin/pc/gateway/sms",
                            icon: "el-icon-s-promotion",
                            open_tab: true
                        },
                        {
                            label: "短信策略",
                            url: "/admin/pc/strategy/sms",
                            icon: "el-icon-guide",
                            open_tab: true
                        }
                    ]
                },
                {
                    label: "网站设置",
                    url: "/admin/user/home",
                    icon: "el-icon-postcard",
                    open_tab: true,
                    children: null
                }
            ]
        };
    },
    //定义方法
    methods: {
        handleOpen(key, keyPath) {
            //console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            //console.log(key, keyPath);
        },
        clickSidebarMenu(label, url, open_tab) {
            //console.log(label, url);
            this.$parent.openTab(label, url, open_tab);
        },
        async setSidebarData() {
            try {
                const response = await apiGetUser(this.$store.state.token);
                //console.log(response);
                if (response.data.code == 200) {
                    this.user = response.data.data;
                } else {
                    this.$message.error(response.data.message);
                    console.log("response>>", response);
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted() {
        this.setSidebarData();
    }
};
</script>

<style lang="scss" scoped></style>

//exports.install = function (Vue, options) {
//    Vue.prototype.Version = function () {
/**
 * 版本号约定：
 * va.b.c.d
 * a: 独立版本，a版本号不同则有可能不向前兼容
 * b: 第n个阶段
 * c：小版本号
 */
//        return "v3.1.6";
//    };
//};

export default {
    version: "v1.1.1",
    apiServer: "http://api.zhaoyu.site"
    // apiServer: "http://localhost:9001"
};

let prettyui = {
    layer: function (obj) {
        return openLayer(obj);
    }
};

function openLayer(obj) {
    //console.log("openLayer:", obj);
    let backdropNode = document.createElement("div");
    backdropNode.className = "pui-layer-backdrop";

    let layerShowNode = document.createElement("div");
    layerShowNode.className = "pui-layer-show";

    let style = "";
    if (typeof obj.width == "undefined" || obj.width == null || obj.width == "") {
        style += "width:100%;";
    } else {
        style += "width:" + obj.width + ";";
    }

    if (typeof obj.height == "undefined" || obj.height == null || obj.height == "") {
        style += "height:100%;";
    } else {
        style += "height:" + obj.height + ";";
    }
    layerShowNode.style = style;

    let layerShowHeaderNode = document.createElement("div");
    layerShowHeaderNode.className = "pui-layer-show-header";
    layerShowHeaderNode.innerText = obj.title;

    let layerShowHeaderCloseNode = document.createElement("i");
    layerShowHeaderCloseNode.className = "el-icon-close";
    layerShowHeaderCloseNode.style = "position: absolute;top: 12px;right: 8px;cursor: pointer;";
    //绑定事件
    layerShowHeaderCloseNode.onclick = function () {
        deleteLayer(backdropNode);
    };
    layerShowHeaderNode.appendChild(layerShowHeaderCloseNode);

    let layerShowContentNode = document.createElement("div");
    layerShowContentNode.className = "pui-layer-show-content";

    let layerIframeNode = document.createElement("iframe");
    layerIframeNode.className = "pui-iframe";
    layerIframeNode.src = obj.url;
    layerShowContentNode.appendChild(layerIframeNode);

    layerShowNode.appendChild(layerShowHeaderNode);
    layerShowNode.appendChild(layerShowContentNode);
    backdropNode.appendChild(layerShowNode);
    document.body.appendChild(backdropNode);
}

function deleteLayer(layerNode) {
    document.body.removeChild(layerNode);
}

export default prettyui;
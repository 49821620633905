import axios from "@/api/baseRequest.js";

//通过token获取用户详情
export const apiGetUser = (token) =>
    axios.get("/api/1/pri/user/get_user_by_token", {
        headers: {
            token: token
        }
    });

//修改名称
export const apiEditName = (formData) => axios.post("/api/1/pri/user/edit_name", formData);

import AdminHome from "@/views/pc/admin/Home.vue";

export default [
    {
        path: "/admin/home",
        name: "adminHome",
        component: AdminHome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/desktop",
        name: "adminDesktop",
        component: () => import("../views/pc/admin/Desktop.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/list",
        name: "listCourse",
        component: () => import("../views/pc/admin/course/ListCourse.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/add",
        name: "addCourse",
        component: () => import("../views/pc/admin/course/AddCourse.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/edit",
        name: "editCourse",
        component: () => import("../views/pc/admin/course/EditCourse.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/setCourseTag",
        name: "setCourseTag",
        component: () => import("../views/pc/admin/course/SetCourseTag.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/setCoursePlatform",
        name: "setCoursePlatform",
        component: () => import("../views/pc/admin/course/SetCoursePlatform.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/course/setCourseAd",
        name: "setCourseAd",
        component: () => import("../views/pc/admin/course/SetCourseAd.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/note/list",
        name: "listNote",
        component: () => import("../views/pc/admin/note/ListNote.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/topic/add",
        name: "addTopic",
        component: () => import("../views/pc/admin/topic/AddTopic.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/topic/edit",
        name: "editTopic",
        component: () => import("../views/pc/admin/topic/EditTopic.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/chapter/add",
        name: "addChapter",
        component: () => import("../views/pc/admin/chapter/Add.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/chapter/edit",
        name: "editChapter",
        component: () => import("../views/pc/admin/chapter/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/note/addNote",
        name: "addNote",
        component: () => import("../views/pc/admin/note/AddNote.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/note/editNote",
        name: "editNote",
        component: () => import("../views/pc/admin/note/EditNote.vue"),
        meta: {
            requiresAuth: true
        }
    }
];

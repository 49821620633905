<style scoped>
header {
    z-index: 99;
    border-bottom: 1px solid #e8eff8;
    /* background-color: #dceeff; */
    background-color: #428bca;
    padding: 0px 100px;
}
header img {
    height: 38px;
    margin: 8px 0px;
    vertical-align: middle;
}
nav {
    display: inline;
    margin-left: 30px;
    vertical-align: middle;
}
nav a {
    color: #ffffff;
    font-family: 华文细黑;
    font-size: large;
    margin-right: 20px;
}
nav a:hover {
    cursor: pointer;
    color: #ff6a00;
}
.active {
    /* font-weight: bold; */
    color: #dd3333;
}
</style>

<template>
    <header>
        <!-- <span style="font-family: 华文细黑; font-size: large; margin-left: 4px">朝雨的个人站</span> -->
        <img src="/pictures/website/zhaoyu.logo.white.gif" alt="朝雨的个人站" />
        <nav>
            <a href="/" id="home" class="active">首页</a>
            <a href="/plan" id="plan">学习路线</a>
            <a href="/course" id="course">视频教程</a>
            <a href="http://youxuji.com/u/zhaoyu" id="note" target="_blank">笔记</a>
            <!-- <a href="/discussions" id="discussions">交流区</a> -->
        </nav>
        <nav style="float: right; margin: 0px">
            <!-- <router-link ref="loginLink" to="/login" style="font-size: small; margin-top: 22px">登录/注册</router-link> -->
            <a ref="loginLink" style="font-size: small; margin-right: 4px; line-height: 55px" href="/login">登录/注册</a>
            <el-dropdown ref="showUsername" style="display: none; font-size: small; margin-top: 20px; color: #ffffff">
                <span class="el-dropdown-link">
                    你好！<router-link to="/private/home" style="font-size: small; margin-right: 4px">{{ user ? user.name : "" }}</router-link>
                </span>
                <el-dropdown-menu>
                    <el-dropdown-item @click.native="jumpTo('/private/home')">个人中心</el-dropdown-item>
                    <el-dropdown-item @click.native="signOut()">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </nav>
        <el-backtop></el-backtop>
    </header>
</template>

<script>
export default {
    data() {
        return {
            user: {},
            selectedMenu: "home"
        };
    },
    methods: {
        getUser() {
            if (this.$store.state.user == "") {
                //显示登录跳转链接
                this.$refs.showUsername.$el.style.display = "none";
                this.$refs.loginLink.style.display = "block";
            } else {
                this.user = JSON.parse(this.$store.state.user);
                //显示用户名
                this.$refs.showUsername.$el.style.display = "block";
                this.$refs.loginLink.style.display = "none";
            }
        },
        jumpTo(url) {
            window.location.href = url;
        },
        //退出登录
        async signOut() {
            //清除token
            await this.$store.dispatch("clearToken");
            //本地删除token
            localStorage.removeItem("token");

            //删除user
            await this.$store.dispatch("clearUser");
            //本地删除user
            localStorage.removeItem("user");

            //刷新页面
            location.reload();
        },
        changeNavStyle() {
            // console.log(this.$route.path);
            //重置上一个导航样式
            document.getElementById(this.selectedMenu).className = "";
            switch (this.$route.path) {
                case "/":
                    this.selectedMenu = "home";
                    document.getElementById(this.selectedMenu).className = "active";
                    break;
                case "/plan":
                    this.selectedMenu = "plan";
                    document.getElementById(this.selectedMenu).className = "active";
                    break;
                case "/course":
                    this.selectedMenu = "course";
                    document.getElementById(this.selectedMenu).className = "active";
                    break;
                case "/note":
                    this.selectedMenu = "note";
                    document.getElementById(this.selectedMenu).className = "active";
                    break;
                default:
                    break;
            }
        }
    },
    mounted() {
        //页面渲染完成调用方法获取数据
        this.getUser();
        this.changeNavStyle();
    }
};
</script>

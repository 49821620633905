<style>
.showName,
.showName a {
    color: #ffffff;
}
.el-dropdown-link {
    cursor: pointer;
    font-size: small;
}
</style>

<template>
    <div class="veadmin-header-container">
        <a class="veadmin-header-logo">网站控制台</a>
        <span class="veadmin-header-version">{{ version }}</span>
        <div class="veadmin-header-datetime">
            <span style="color: #fff; margin-left: 14px">当前时间：{{ getTime(date) }}</span>
            <span style="font-size: x-small; color: #cccccc; margin-left: 4px">{{ getCustomDate(date) }}</span>
        </div>
        <nav class="veadmin-header-menu">
            <el-dropdown>
                <span class="el-dropdown-link showName" style="font-size: small">
                    你好！
                    <a @click="jumpTo('/private/home')">{{ user.name }}</a>
                </span>
                <el-dropdown-menu>
                    <!-- <el-dropdown-item @click.native="openTab('个人中心', '/admin/pc/user/home', true)">个人中心</el-dropdown-item> -->
                    <el-dropdown-item @click.native="jumpTo('/private/home')">个人中心</el-dropdown-item>
                    <el-dropdown-item @click.native="signOut()">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </nav>
    </div>
</template>

<script>
import { GetTime, GetCustomDate } from "@/assets/ve-admin/TimeUtils.js";
import Base from "@/base.js";

export default {
    data() {
        return {
            version: Base.version,
            date: new Date(),
            user: {}
        };
    },
    methods: {
        getTime(date) {
            return GetTime(date);
        },
        getCustomDate(date) {
            return GetCustomDate(date);
        },
        openTab(label, url, open_tab) {
            this.$parent.openTab(label, url, open_tab);
        },
        //退出登录
        async signOut() {
            //清除token
            await this.$store.dispatch("clearToken");
            //本地删除token
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");

            //删除user
            await this.$store.dispatch("clearUser");
            //本地删除user
            localStorage.removeItem("user");
            sessionStorage.removeItem("user");

            //刷新页面
            location.reload();
        },
        getUser() {
            if (this.$store.state.user == "") {
                //跳转到登录页面
                window.location.href = "/login?target=" + window.location.href;
            } else {
                this.user = JSON.parse(this.$store.state.user);
                if (this.user.type == "site_master") {
                    //站长，不需要跳转
                } else {
                    //不是站长，跳转到个人中心
                    window.location.href = "/private/home";
                }
            }
        },
        jumpTo(url) {
            window.location.href = url;
        }
    },
    mounted() {
        //更新时间
        let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
        this.timer = setInterval(() => {
            _this.date = new Date(); // 修改数据date
        }, 1000);

        this.getUser();
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    }
};
</script>

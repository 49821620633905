import PrivateHome from "@/views/pc/private/Home.vue";

export default [
    {
        path: "/private/home",
        name: "PrivateHome",
        component: PrivateHome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/private/editName",
        name: "EditName",
        component: () => import("../views/pc/private/EditName.vue"),
        meta: {
            requiresAuth: true
        }
    }
];

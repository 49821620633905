<style lang="scss">
@import "@/assets/prettyui/prettyui.scss";
</style>

<template>
    <div>
        <Header />
        <div style="margin: 10px 100px">
            <el-container>
                <el-aside width="100px">
                    <img style="width: 100px; height: 100px; border-radius: 50px" :src="apiServer + '/pub/images/portrait/default.jpg'" />
                </el-aside>
                <el-main style="padding: 4px 0px 0px 20px">
                    <div style="font-weight: bold; font-size: x-large; line-height: 90px">
                        {{ user.name }}
                        <el-button
                            ref="buttonAdmin"
                            type="warning"
                            style="margin-left: 20px; display: none; vertical-align: middle"
                            @click="jumpTo('/admin/home')"
                            >超级管理员</el-button
                        >
                    </div>
                </el-main>
            </el-container>
        </div>
        <el-tabs type="border-card" style="margin: 10px 100px">
            <el-tab-pane label="个人设置" style="padding: 50px">
                <div>
                    <span style="width: 250px; display: block; float: left; line-height: 32px">名称：{{ user.name }}</span>
                    <el-button type="primary" @click="openLayer('修改名称', '400px', '240px')">修改名称</el-button>
                    <el-button type="primary" @click="openLayer('修改密码', '400px', '288px')">修改密码</el-button>
                </div>
                <div style="margin-top: 50px">
                    <span style="width: 250px; display: block; float: left; line-height: 32px">手机号码：{{ user.phone }}</span>
                    <el-button type="primary" @click="openLayer('修改手机号码', '400px', '288px')">修改手机号码</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="提问">提问列表</el-tab-pane>
            <el-tab-pane label="收到回复">收到的回复列表</el-tab-pane>
            <el-tab-pane label="评论">评论列表</el-tab-pane>
        </el-tabs>
        <Footer />
    </div>
</template>

<script>
import Header from "@/views/pc/common/Header.vue";
import Footer from "@/views/pc/common/Footer.vue";
import base from "@/base.js";
import prettyui from "@/assets/prettyui/prettyui.js";

export default {
    //注册组件
    components: {
        Header,
        Footer
    },
    data() {
        return {
            apiServer: base.apiServer,
            user: {
                name: "",
                phone: ""
            }
        };
    },
    methods: {
        // 通过store获取用户信息
        getUser() {
            if (this.$store.state.user == "") {
                //跳转到登录页面
                window.location.href = "/login?target=" + window.location.href;
            } else {
                this.user = JSON.parse(this.$store.state.user);
                if (this.user.type == "site_master") {
                    this.$refs.buttonAdmin.$el.style.display = "unset";
                } else {
                    this.$refs.buttonAdmin.$el.style.display = "none";
                }
            }
        },
        jumpTo(url) {
            window.location.href = url;
        },
        openLayer(title, width, height) {
            prettyui.layer({ title: title, url: "/private/editName", width: width, height: height });
        },
        closeLayer() {
            //iframe关闭layer入口
            let nodes = document.getElementsByClassName("pui-layer-backdrop");
            for (let i = 0; i < nodes.length; i++) {
                document.body.removeChild(nodes[i]);
            }
        },
        listenerFun(e) {
            //子页面调用方法
            // console.log(e.data);
            this.closeLayer();
            if (e.data.code == "200") {
                this.$message({
                    message: e.data.message,
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        }
    },
    mounted() {
        //页面渲染完成调用方法获取数据
        this.getUser();

        //监听iframe
        window.addEventListener("message", this.listenerFun);
    },
    beforeDestory() {
        // 页面销毁前，务必去除监听器，否则会造成资源泄露！
        window.removeEventListener("message", this.listenerFun);
    }
};
</script>

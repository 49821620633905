<style scoped>
.main {
    min-width: 1200px;
    padding: 8px 100px;
    /* overflow: auto; */
    /* position: absolute; */
    /* bottom: 0px;
    top: 62px;
    right: 0px;
    left: 0px; */
}
.left_box {
    float: left;
    width: 100%;
}
.left_column {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    padding: 12px;
    margin-bottom: 10px;
    margin-right: 400px;
    overflow: auto;
}
.right_box {
    position: relative;
    float: left;
    width: 386px;
    margin-left: -386px;
}
.right_column {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    padding: 12px;
    margin-bottom: 10px;
}
.div_button:hover {
    cursor: pointer;
    border: 1px solid #ff5e52;
}
.course_img {
    float: left;
    height: 150px;
    margin-right: 12px;
}
.course_img:hover {
    cursor: pointer;
}
.title {
    /* font-weight: bold;
			font-size: larger;
			margin-right: 4px; */
    font-size: x-large;
    margin-right: 6px;
}
.tag {
    margin-right: 4px;
    background: #edeff2;
    border-radius: 4px;
    font-size: x-small;
    color: #667280;
    padding: 2px 6px;
}
.external_link {
    padding-top: 12px;
}
.external_link span {
    font-size: small;
}
.base_button {
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    margin-right: 6px;
}
.base_button:hover {
    cursor: pointer;
    border: 1px solid #ff0000;
}
.img_button {
    padding-top: 4px;
}
.text_button {
    font-weight: bold;
    padding: 8px 12px;
}
.text_button_small {
    padding: 6px 10px;
    font-size: small;
}
.small_logo {
    height: 22px;
}
.homepage_portrait {
    float: left;
    margin-right: 8px;
    vertical-align: middle;
    height: 100px;
    border-radius: 50px;
}
.small_button {
    vertical-align: -webkit-baseline-middle;
    padding: 4px 12px;
    margin-left: 4px;
    color: #fff;
    background: #ff7923;
    border: 0px;
    border-radius: 20px;

    /* 隐藏按钮 */
    background: #fff;
}
.small_button:hover {
    cursor: pointer;
    background: #f59966;

    /* 隐藏按钮 */
    cursor: default;
    background: #fff;
}
.mytag {
    border: 1px solid #edeff2;
    border-radius: 4px;
    font-size: x-small;
    color: #667280;
    padding: 2px 6px;
    float: left;
    margin-right: 6px;
    margin-top: 6px;
}
</style>

<template>
    <div>
        <Header />
        <div class="main">
            <div class="left_box">
                <div class="left_column">
                    <img
                        src="/pictures/course/mysql_course_cover_02.gif"
                        class="course_img"
                        @click="openNewWindow('https://edu.51cto.com/course/32462.html')"
                    />
                    <a class="title" href="https://edu.51cto.com/course/32462.html" target="_blank">MySQL从入门到精通</a>
                    <span class="tag">MySQL</span>
                    <span class="tag">从入门到精通</span>
                    <div class="external_link">
                        <span style="font-size: small; color: #999">本课程深入讲解MySQL高级用法，进一步了解MySQL。</span>
                    </div>
                    <div class="external_link">
                        <span>发布平台：</span>
                        <button class="base_button img_button" @click="openNewWindow('https://edu.51cto.com/course/32462.html')">
                            <img class="small_logo" src="/pictures/platform/edu.51cto.logo.png" />
                        </button>
                    </div>
                    <div class="external_link">
                        <span>资料下载：</span>
                        <button class="base_button img_button" @click="openNewWindow('https://pan.baidu.com/s/1c1KBRcSDikUjzr2Z8mOXMg?pwd=h35r')">
                            <img class="small_logo" src="/pictures/platform/pan.baidu.logo.gif" />
                        </button>
                        <span style="margin-left: 6px">提取码：h35r</span>
                    </div>
                    <div class="external_link">
                        <span>推广：</span>
                        <button
                            class="base_button text_button_small"
                            @click="
                                openNewWindow(
                                    'https://cloud.tencent.com/act/cps/redirect?redirect=2446&cps_key=00ed61e92748fbbbf799486a6bc05854&from=console'
                                )
                            "
                        >
                            <span style="color: #006eff">腾讯云特惠</span>
                        </button>
                        <button
                            class="base_button text_button_small"
                            @click="openNewWindow('https://www.aliyun.com/daily-act/ecs/activity_selection?userCode=zahxwqv2')"
                        >
                            <span style="color: #ff6a00">阿里云特惠</span>
                        </button>
                    </div>
                </div>
                <div class="left_column">
                    <img
                        src="/pictures/course/mysql_course_cover_01.gif"
                        class="course_img"
                        @click="openNewWindow('https://edu.51cto.com/course/31689.html')"
                    />
                    <a class="title" href="https://edu.51cto.com/course/31689.html" target="_blank">MySQL零基础入门视频教程</a>
                    <span class="tag">MySQL</span>
                    <span class="tag">零基础入门</span>
                    <div class="external_link">
                        <span style="font-size: small; color: #999">本课程章节分明，先易后难，循序渐进，小白也适合学习本课程入门MySQL。</span>
                    </div>
                    <div class="external_link">
                        <span>发布平台：</span>
                        <button class="base_button img_button" @click="openNewWindow('https://edu.51cto.com/course/31689.html')">
                            <img class="small_logo" src="/pictures/platform/edu.51cto.logo.png" />
                        </button>
                        <!-- <button class="base_button img_button" @click="openNewWindow('https://ke.qq.com/course/5641805?_wv=2147487745')">
					<img class="small_logo" src="/pictures/platform/ke.qq.logo.png" />
				</button> -->
                    </div>
                    <div class="external_link">
                        <span>资料下载：</span>
                        <button class="base_button img_button" @click="openNewWindow('https://pan.baidu.com/s/1YAap1lILIK3MBg1hERE2nQ?pwd=vxvp')">
                            <img class="small_logo" src="/pictures/platform/pan.baidu.logo.gif" />
                        </button>
                        <span style="margin-left: 6px">提取码：vxvp</span>
                    </div>
                </div>

                <!-- <div class="left_column" style="padding: 0px; border-top: 0px"> -->
                <div class="left_column" style="padding: 0px">
                    <!-- <div style="background-color: #ddecf9; padding: 10px"> -->
                    <div style="padding: 10px; border-bottom: 1px solid #eaeaea">
                        <h1 style="margin: 0px; font-size: x-large; color: #004b9f">教程录制计划</h1>
                    </div>
                    <div style="padding: 20px">
                        <a class="title">MySQL项目实战</a>
                        <span class="tag">MySQL</span>
                        <span class="tag">实战</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年5月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <!-- <a class="title">HTML零基础入门</a> -->
                        <a class="title">HTML+CSS+JavaScript零基础入门</a>
                        <span class="tag">HTML</span>
                        <span class="tag">CSS</span>
                        <span class="tag">JavaScript</span>
                        <span class="tag">零基础入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年6月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <!-- <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">CSS快速入门</a>
                        <span class="tag">CSS</span>
                        <span class="tag">快速入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年7月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">JavaScript(ECMAScript 2015)快速入门</a>
                        <span class="tag">JavaScript</span>
                        <span class="tag">ECMAScript</span>
                        <span class="tag">快速入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年8月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p> -->

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">VUE3快速入门</a>
                        <span class="tag">VUE3</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年8月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">Java零基础入门</a>
                        <span class="tag">Java</span>
                        <span class="tag">零基础</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年10月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <!-- <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">Java Web快速入门</a>
                        <span class="tag">JavaWeb</span>
                        <span class="tag">快速入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">规划筹备中...</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p> -->

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">SSM（Spring+SpringMVC++MyBatis）快速入门</a>
                        <span class="tag">SSM</span>
                        <span class="tag">Spring</span>
                        <span class="tag">SpringMVC</span>
                        <span class="tag">MyBatis</span>
                        <span class="tag">快速入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">计划发布时间：2023年12月</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">SpringBoot整合SSM开发项目实战</a>
                        <span class="tag">SSM</span>
                        <span class="tag">SpringBoot</span>
                        <span class="tag">项目实战</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">规划筹备中...</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>

                        <hr style="height: 1px; border: none; border-top: 1px dashed #b3b3b3" />
                        <a class="title">SpringSecurity快速入门</a>
                        <span class="tag">SpringSecurity</span>
                        <span class="tag">快速入门</span>
                        <p>
                            <span style="color: #666666; vertical-align: middle; margin-right: 15px; font-size: small">规划筹备中...</span>
                            <el-tag effect="plain" type="info">敬请期待......</el-tag>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right_box">
                <div class="right_column">
                    <img src="/pictures/website/portrait.gif" class="homepage_portrait" />
                    <div style="float: left">
                        <span style="font-size: 32px; vertical-align: middle">朝雨</span>
                        <button class="small_button" @click="tip('该功能正在开发中...')">关注</button>
                        <span style="font-size: x-small; color: #999; vertical-align: -webkit-baseline-middle; color: #fff">获取最新动态和优惠</span>
                    </div>
                    <div>
                        <span class="mytag">MySQL</span>
                        <span class="mytag">Java</span>
                        <span class="mytag">Vue</span>
                        <span class="mytag">SpringBoot</span>
                        <span class="mytag">SpringMVC</span>
                        <span class="mytag">MyBatis</span>
                        <span class="mytag">全栈开发</span>
                    </div>
                    <hr style="width: 100%; border: 0px" />
                    <div>
                        <p>&emsp;&emsp;具有多年Java、MySQL和前后端服务经验，熟悉Vue、SpringBoot、SpringMVC、MyBatis等框架。</p>
                    </div>
                </div>
                <div class="right_column div_button" style="padding: 0px" @click="openNewWindow('https://edu.51cto.com/lecturer/15691893.html')">
                    <div style="text-align: center; color: #fff; background-color: #ff5e52; border-radius: 6px 6px 0px 0px; padding: 6px 12px">
                        <!-- 教 程 发 布 平 台 -->
                        教程发布平台
                    </div>
                    <!-- <div style="padding: 12px;"> -->
                    <div style="padding: 4px; text-align: center">
                        <!-- <button class="base_button img_button" @click="openNewWindow('https://ke.qq.com/teacher/92162930?tuin=365a6e43')">
					<img src="/pictures/platform/ke.qq.logo.png" style="height: 32px" />
				</button> -->
                        <!-- <button class="base_button img_button" @click="openNewWindow('https://edu.51cto.com/lecturer/15691893.html')">
					<img src="/pictures/platform/edu.51cto.logo.png" style="height: 32px" />
				</button> -->
                        <button class="base_button img_button" style="border: 0px; margin: 0px">
                            <img src="/pictures/platform/edu.51cto.logo.png" style="height: 32px" />
                        </button>
                    </div>
                </div>
                <div class="right_column" style="padding: 0px">
                    <div style="text-align: center; color: #fff; background-color: #428bca; border-radius: 6px 6px 0px 0px; padding: 6px 12px">
                        <!-- 推&ensp;广 -->
                        推 广
                    </div>
                    <div style="padding: 12px">
                        <button
                            class="base_button text_button"
                            @click="
                                openNewWindow(
                                    'https://cloud.tencent.com/act/cps/redirect?redirect=2446&cps_key=00ed61e92748fbbbf799486a6bc05854&from=console'
                                )
                            "
                        >
                            <span style="color: #006eff">腾讯云特惠</span>
                        </button>
                        <button
                            class="base_button text_button"
                            @click="openNewWindow('https://www.aliyun.com/daily-act/ecs/activity_selection?userCode=zahxwqv2')"
                        >
                            <span style="color: #ff6a00">阿里云特惠</span>
                        </button>
                        <!-- 腾讯云https://console.cloud.tencent.com/spread -->
                        <!-- 阿里云https://promotion.aliyun.com/ntms/yunparter/personal-center.html#/ -->
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../common/Header.vue";
import Footer from "../common/Footer.vue";

export default {
    //注册组件
    components: {
        Header,
        Footer
    },
    data() {
        return {};
    },
    methods: {
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {}
};
</script>
